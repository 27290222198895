import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import Home from './Components/Home';
import FellowApp from './Components/FellowApp';
import {SnackbarProvider} from 'notistack';
import WorkspaceLogin from "./Components/WorkspaceLogin";
import NodAbout from "./Components/NodAbout";
import HaleTrade from "./Components/HaleTrade";
import CoopPlans from "./Components/CoopPlans";
import Elephante from "./Components/Elephante";

export default function App() {

    const theme =
        createTheme({
            palette: {
                type: 'light',
                primary: {
                    main: '#90ab43',
                    contrastText: '#202020',
                },
                secondary: {
                    main: '#495703',
                    contrastText: '#202020',
                },
            },
        });

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <Router>
                    <Switch>
                        <Route path="/red-dirt-coworkers">
                            <FellowApp/>
                        </Route>
                        <Route path="/red-dirt-cooperative">
                            <CoopPlans />
                        </Route>
                        <Route path="/workspaces/kauai">
                            <HaleTrade />
                        </Route>
                        <Route path="/workspaces/elephante">
                            <Elephante />
                        </Route>
                        <Route path="/workspaces">
                            <WorkspaceLogin />
                        </Route>
                        <Route path="/nod">
                            <NodAbout />
                        </Route>
                        <Route path="/">
                            <Home/>
                        </Route>
                    </Switch>
                </Router>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

