import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Slider from '@material-ui/core/Slider';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withStyles} from "@material-ui/core/styles";
import styles from "../styles";

class GalleryDialog extends Component {
    constructor(props) {
        super(props);
        let def = window.innerWidth > 900 ? 275 : window.innerWidth > 500 ? 200 : 100;
        this.state = {open: true, imageHeight:def, expanded: props.expandedDefault};
    }

    zoomImage(image) {
        this.setState({zoom: image});
    }

    handleClose() {
        this.setState({open: false})
        this.props.onClose();
    }

    render() {

        return (
            <Dialog open={this.state.open} onClose={e => this.handleClose()}
                    aria-labelledby="rent-gallery-form" maxWidth={'xl'} fullWidth={true}>
                <DialogTitle id="rent-gallery-form">

                    <Typography id="image-scaler" gutterBottom>
                        Image Height
                    </Typography>
                    <Slider
                        value={this.state.imageHeight}
                        aria-labelledby="image-scaler"
                        onChange={(e,val) => this.setState({imageHeight:val})}
                        valueLabelDisplay="auto"
                        step={25}
                        marks
                        min={100}
                        max={window.innerHeight}
                    />

                </DialogTitle>
                <DialogContent style={{padding:0}}>
                    {Object.keys(this.state.expanded).map((name, sec) => {
                        let section = this.props.projects.children.find(o => o.name === name);
                        if (!section && this.props.projects.name === name) section = this.props.projects;
                        if (!section || !section.children || section.children.length === 0) return '';
                        return (
                            <Accordion key={`${sec}-gallery-acc`}
                                       expanded={this.state.expanded[section.name]}
                                       className={this.props.classes.accordion}
                                       onChange={() => {
                                           let obj = {...this.state.expanded};
                                           obj[section.name] = !obj[section.name];
                                           this.setState({expanded:obj})
                                       }}>
                                <AccordionSummary
                                    className={this.state.expanded[section.name] === true ? this.props.classes.accordionHead : ''}
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls={`${sec}-gallery-panel`}
                                    id={`${sec}-gallery-header`} >
                                    <Typography>{section.name.replace('_', ' ')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails id={`${sec}-gallery-panel`}>
                                    <Grid container justify={'center'}>
                                        {section.children.map((image) => {
                                            return <Grid item key={image.path} style={{height: this.state.imageHeight, padding: 1}}>
                                                {(image.path.indexOf('.mp4') > 0) ?
                                                    <video src={image.path}
                                                           controls={true} muted={true} autoPlay={false} loop={true} style={{height: '100%'}}/>
                                                    :
                                                    <img onClick={e => this.zoomImage(image)} style={{height: '100%'}}
                                                         src={image.path} alt={image.name}/>
                                                }
                                            </Grid>
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>)
                    })}
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(GalleryDialog);
