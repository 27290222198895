import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import styles from "../styles";
import ByLaws from "./ByLaws";
import LandPlanSlideshow from "./LandPlanSlideshow";
import {NavLink} from "react-router-dom";

const itemData = [
    {img: "//kapunahale.com/inspiration/mushrooms.jpeg", title: "18x18\" Drying / Powdering Facility"},
    {img: "//kapunahale.com/inspiration/nursery.jpeg", title: "Plant Nursery"},
    {img: "//kapunahale.com/inspiration/fruit-stand-mobile.png", title: "9x9\" Mobile Market Trailer"},
]

/*
    {img:"//kapunahale.com/photos/projects/21_900x900.jpg", title:"Coconuts"},
    {img:"//kapunahale.com/turmeric.jpeg", title:"Turmeric"},
*/

const productsData = [
    {img: "//kapunahale.com/inspiration/flowers-ginger.jpeg", title: "Flowers"},
    {img: "//kapunahale.com/inspiration/nursery.jpeg", title: "Plant Nursery"},
    {img: "//kapunahale.com/inspiration/ceramics-studio.jpeg", title: "Ceramics Studio"},
    {img: "//kapunahale.com/inspiration/outdoorshower-300x0.jpg", title: "Garden Spa"},
    {img: "//kapunahale.com/inspiration/sidecar/A.jpg", title: "Side cart market"},
    {img: "//kapunahale.com/inspiration/wood-bamboo-stone-tulum-jungle-gym.jpeg", title: "Outdoor Gym"},
]

class CoopPlans extends React.Component {

    componentDidMount() {
        window.logEvent('page_view', {page_title: 'CoopPlans'});
    }

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <div id='coopplans'  style={{paddingBottom: 40, zIndex:10000, position:'relative'}}>
                    <AppBar position="relative" color={'secondary'}>
                        <Toolbar style={{justifyContent:'space-between'}}>
                            <NavLink to={'/workspaces'}><Button>Go Back</Button></NavLink>
                            <Typography variant="overline" style={{color: "#fff"}}>
                                {this.props.title}
                            </Typography>
                            <Typography variant="h6" style={{textAlign: 'right'}}>
                                <NavLink to={'/'}><img src='/icon.png' alt="logo" height='40'/></NavLink>
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container direction='column' spacing={0} >

                        <div style={{padding: '1% 3%', maxWidth: 1100, margin: 'auto'}}>
                            <Typography variant={'body2'} style={{fontSize:'140%', textAlign:'center'}} componet={'h1'}>
                                This is <strong>vision board</strong> for a new Grocery and Artist Cooperative at Kapuna Hale
                                <br />
                                with a 2nd draft outline of <strong>Membership By Laws</strong>.
                            </Typography>
                        </div>

                        <ImageList rowHeight={250} cols={3}>
                            {itemData.map((item) => (
                                <ImageListItem key={item.img}>
                                    <div style={{backgroundImage:`url(${item.img})`}} className={classes.bgImg} title={item.title} />
                                    <ImageListItemBar
                                        title={item.title}
                                        classes={{
                                            root: classes.titleBar,
                                            title: classes.title,
                                        }}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>

                        <div style={{padding: '2% 3% 1% 3%', maxWidth: 1100, width:'100%', margin: 'auto'}}>

                            <Divider/>
                            <Typography variant={'overline'}>ROUND 1 GOALS</Typography>

                            <Typography style={{margin: '10px 0'}} variant={'body1'} component={'div'}>
                                <ul className={classes.ulNum}>
                                    <li><a target={'_blank'} href={'https://drive.google.com/drive/folders/1SecBcSUEzFGt7Wh2MGtMvUXYIm-mJSh0?usp=sharing'}>Zoning Permit</a> Applications</li>
                                    <li><a target={'_blank'} href={'https://drive.google.com/drive/folders/1SecBcSUEzFGt7Wh2MGtMvUXYIm-mJSh0?usp=sharing'}>Building Permit</a> Applications</li>
                                    <li>Use Permit Applications</li>
                                    <li>Clear old storage sheds and reorganize around new ones</li>
                                    <li>Terraform, grade and landscape all project sites</li>
                                    <li>Restore old greenhouse as plant nursery.</li>
                                    <li>Organize dedicated green waste sites</li>
                                    {/*
                                     GreenWaste:
                                     - Coconut Husks
                                     - Coconut palm frawns
                                     - Area palm frawns
                                     - Guinea Grass
                                     - Heli Heli
                                     - Java Plumb
                                     - Wood and branches
                                     */}
                                </ul>
                            </Typography>

                        </div>

                        <LandPlanSlideshow />

                        <div style={{padding: '2% 3% 1% 3%', maxWidth: 1100, width:'100%', margin: 'auto'}}>
                            <Typography variant={'overline'}>ROUND 2 GOALS AND BEYOND</Typography>
                        </div>

                        <ImageList rowHeight={140} cols={window.innerWidth > 500 ? 4.5 : 3.5} className={classes.gridList}>
                            {productsData.map((item) => (
                                <ImageListItem key={item.img}>
                                    <div style={{backgroundImage:`url(${item.img})`}} className={classes.bgImg} title={item.title} />
                                    <ImageListItemBar  title={item.title} />
                                </ImageListItem>
                            ))}
                        </ImageList>

                        <div style={{padding: '2% 3% 1% 3%', maxWidth: 1100, width:'100%', margin: 'auto'}}>


                            <Typography style={{margin: '10px 0', }} variant={'body1'} component={'div'}>
                                <ul className={classes.ul}>
                                    <li>Climate controlled powdering / curing facility</li>
                                    <li>Plant fruit tree orchard or forest</li>
                                    <li>Build industrial greenhouse for microgreens</li>
                                    <li>9x9" Mobile Market Trailer selling resident farmer's Coconuts, Turmeric, Cocoa, and assorted fruits and veggies</li>
                                    <li>Host pull-up farmers' markets & events on main lawn</li>
                                    <li>Bring pop-up markets to poplar beaches, parks, and craft events</li>
                                    <li>Register Cooperative Business with Hawaii and advertise benefits of membership</li>
                                </ul>
                            </Typography>
                        </div>

                        <div style={{padding: '1% 3%', maxWidth: 1100, margin: 'auto'}}>

                            <Divider style={{marginTop:50}} />
                            <Typography variant={'overline'}>Cooperative Business Contract Template</Typography>

                            <Typography style={{margin: '10px 0'}} variant={'body1'}>
                                The outline below is derived from
                                these <a href={"https://www.co-oplaw.org/knowledge-base/cooperative-bylaws/#Sample_Bylaws"}>Sample
                                Membership ByLaws by Co-OpLaw.org</a> and
                                is only intended to summarize benefits and responsibilities. We will hash out the details with a
                                lawyer once close to an agreement.
                            </Typography>
                            <blockquote className={classes.blockquote}>
                                Text styles like this are explanations and notes for discussion
                            </blockquote>

                        </div>

                        <div style={{padding: '1% 3%', maxWidth: 1100, margin: 'auto'}}>
                            <ByLaws />
                        </div>

                    </Grid>

                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CoopPlans);

