import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from "@material-ui/core/styles";


class NodTrial extends Component {
    constructor(props) {
        super(props);
        this.state = {open:false}
    }


    render() {
        const {classes} = this.props;

        if (this.state.open === false) {
            return <Button className={classes.cta} onClick={e => this.setState({open: true})}>View Trial Menu</Button>
        }

        return (
            <Dialog open={this.state.open} onClose={e => this.setState({open: false})}
                    aria-labelledby="form-dialog-title" >
            <div className={classes.container}>
                <div>
                    <h1>N.O.D Meals</h1>
                    <p>Nourishment On Demand</p>
                </div>

                <h2 className='ideaTitle'>2-day Trial Menu</h2>

                <div className='generalInfo'>
                    <h3>Wednesday</h3>
                    <p><strong>Lunch:</strong> Kale Salad ft. Peaches, Strawberries, Blueberries, Hemp Seeds and a Cinnamon vinaigrette</p>
                    <p><strong>Dinner:</strong> Roasted Peach Chana</p>
                </div>

                <div className="block">
                    <h3>Thursday</h3>
                    <p><strong>Lunch:</strong> Balancing Macrobiotics Plate ft.</p>
                    <p><strong>Dinner:</strong> Taco Salad with Walnut</p>
                </div>

                <div className="block">
                    <h3>$80</h3>  (excluding additions)
                    <p>free delivery for trial</p>
                </div>

                <div className="block">
                    <p>In order to secure your spot please make a payment on Paypal. Payments can be sent to nourishmentondemand@gmail.com
                    </p>
                </div>

            </div>
            </Dialog>
        );
    }
}

const styles = theme => ({
    container: {
        backgroundColor: '#3B5800',
        padding:'2%',
        color: '#fff'
    },
    title: {
        textAlign: 'center',
        marginBottom:50
    },
    h3: {
        fontWeight: 700,
        fontSize: 16,
    },
    cta: {
        backgroundColor: '#3B5800',
        color: '#fff'
    }
});


export default withStyles(styles)(NodTrial);
