import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';

class MyAccordion extends React.Component {

    constructor(p) {
        super(p);
        this.state = {expanded:p.expanded || false}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.expanded !== this.props.expanded) {
            this.setState({expanded: this.props.expanded})
        }
    }

    render() {
        const {classes} = this.props;
        const id = this.props.title
        return (
                <Accordion expanded={this.state.expanded === true} onChange={() => this.setState({expanded:!this.state.expanded})}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${id}-content`} id={`${id}-header`}>
                        <Avatar className={classes.sectionNum}>{this.props.section}</Avatar>
                        <Typography>{this.props.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.props.children}
                    </AccordionDetails>
                </Accordion>
        );
    }
}


const styles = theme => ({
    sectionNum: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize:11,
        marginRight:5
    },
});


export default withStyles(styles)(MyAccordion);

