import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import NodTrial from "./NodTrial";

class NodAbout extends Component {

    render() {
        const {classes} = this.props;

        return <div className={classes.container}>
                <div className={classes.title}>
                    <h1>N.O.D Meals</h1>
                    <p>Nourishment On Demand</p>
                </div>

                <div className='generalInfo'>
                    <h3 className={classes.h3}>ABOUT</h3>
                    <p>
                        <img src={'/images/purpose.png'} align='right' />
                        My name is Sammie. I am the hands behind NOD. I have spent most of my life living in the heart of Silicon Valley. The last decade of my career was spent focusing on User Experience Design for Web or Mobile Applications, which refined my skills in technical design and contextual empathy.
                    </p>

                    <p>I truly enjoy the work behind creating virtual experiences. However, I also have a passion for health through food. My friends know me as the girl with an apothecary for a kitchen pantry. I have been developing recipes with a focus on nourishment for as long as I can remember. I will cook rice with burdock root infused water, or throw three cups of spinach into a sauce. My goal is to sneak in nourishment for myself and others whenever I can.
                        After becoming a mother with a full-time job, I quickly began to find that cooking the way I used to was much too time-consuming. I did quick meals, or ate my son’s leftovers. My health began to deteriorate. I eventually moved off island for a period of time and became a client for a health-focused meal prep company. The massive shifts I saw in my metabolic health, which trickled into my mental and physical wellbeing were astounding. My life changed during that time. I was able to show up as a better mother, wife, friend, daughter, and team member to my colleagues.</p>
                </div>

                <div className="block">
                    <h3 className={classes.h3}>STARTING NOD</h3>
                    <p>Once I returned to Kaua’i I found myself dealing with the same issue. I tried my best to find a meal prep service similar to the one I had in CA. With no option in sight, I decided to combine my background in experience design with my love for curating nourishing foods and started NOD, Nourishment On-Demand. And now here we are. We are still in the process of getting set up around the markets. Stay on the look-out, you’ll see us there soon :)
                    </p>
                </div>

                <div className="block">
                    <h3 className={classes.h3}>INGREDIENTS</h3>
                    <p>I source as much as I possibly can from the Aina. All ingredients are organic and high-quality, including any spice or adaptogen that’s added. You will receive an email with details on each meal prior to the delivery.
                    </p>
                </div>

                <div className="block">
                    <h3 className={classes.h3}>NOURISHED</h3>
                    <p>I truly believe that with proper nourishment we will all be able to show up as our best selves for one another. I pray that as a result of being deeply nourished, it will allow us to come together and create a strong, joyful and resilient community.
                    </p>
                </div>


                <div className="block">
                    <p>Stay well,
                    <br />Sammie Khalil-Taylor</p>
                </div>

                <div style={{textAlign:'center'}}>
                    <p><NodTrial /></p>
                    <p><Button className={classes.button} variant={'contained'} component={'a'}
                               target={"_blank"}
                               href='https://docs.google.com/forms/u/3/d/e/1FAIpQLSfR0EcmbHjs-xvqMVN6Ee0zJ5TFMFsueFzmRh_5xmGcwuqdBg/viewform?usp=send_form'>Register</Button></p>

                    <p>Questions?<br />Feel free to reach out at NourishmentOnDemand@gmail.com</p>

                </div>


            </div>
    }
}

const styles = theme => ({
    container: {
        backgroundColor: '#FEFBE4',
        padding:'3%',
        minHeight: '100vh',
        color: '#536C1C'
    },
    title: {
        color: '#3B5800',
        textAlign: 'center',
        marginBottom:50
    },
    h3: {
        color: '#3B5800',
        fontWeight: 700,
        fontSize: 16,
    },
    button: {
        backgroundColor: '#3B5800',
        color: '#fff'
    }
});


export default withStyles(styles)(NodAbout);
