import React, {useEffect, useState} from "react";

const imgList = [
//     '/images/gardenhwy/Outdoors/IMG_1158.jpg',
    '/images/gardenhwy/Outdoors/IMG_1178.JPG',
    '/images/gardenhwy/Outdoors/20210514_165718.jpg',
    '/images/gardenhwy/Outdoors/IMG_1203.JPG',
    '/images/gardenhwy/Outdoors/IMG_1198.jpg',
    '/images/gardenhwy/Outdoors/IMG_1186.JPG',
    '/images/gardenhwy/Outdoors/20201110_161504.jpg',
    '/images/gardenhwy/Outdoors/20201107_155129.jpg',
    '/images/gardenhwy/Outdoors/20210101_163923.jpg',
    // '/images/gardenhwy/Outdoors/20201107_154511.jpg',
    '/images/gardenhwy/Outdoors/20201207_164018.jpg',
    '/images/gardenhwy/Outdoors/IMG_1195.JPG',
    '/images/gardenhwy/Outdoors/20200928_141830.jpg',
    '/images/gardenhwy/Outdoors/IMG_1181.JPG',
]


function BgImageFader() {
    const slidePresentationTime = 2500 // 3s
    const [currentSlide, setCurrentSlide] = useState(0) // set currrent slide index

    useEffect(() => {
        setTimeout(() => {
            setCurrentSlide((currentSlide + 1) % imgList.length); // change current slide to next after 3s
        }, slidePresentationTime);
    }, [currentSlide])

    return (
        <div>
            {imgList.map((image, index) => (
                index > currentSlide - 2 && index < currentSlide + 2
                ?
                    <img
                        id={'heroImg'+index}
                        key={'heroImg'+index}
                        alt={'slide img'+ index}
                        className={index === currentSlide ? 'fullImg active' : 'fullImg'}
                        src={image}
                        style={{zIndex: `-${index+1}`}}
                    />
                    :
                ''
            ))}
        </div>
    );
}


export default BgImageFader;
