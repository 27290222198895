import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Slider from '@material-ui/core/Slider';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withStyles} from "@material-ui/core/styles";
import styles from "../styles";

const projects = require('../data/gardenhwy.json');
projects.children.forEach(section => {
    if (section.children) {
        section.children.sort((a,b) => new Date(a.time).getTime() - new Date(b.time).getTime())
    }
})

let expandedDefault = {
    'Outdoors': true,
    'Living_Room': true,
    'Loft': false,
    'Kitchen': false,
    'Master_Bedroom': false,
    'Guest_Bedroom': false
}

class Gallery extends Component {
    constructor(props) {
        super(props);
        let def = window.innerWidth > 900 ? 275 : window.innerWidth > 500 ? 200 : 100;
        this.state = {open: false, imageHeight:def, expanded: expandedDefault};
    }

    zoomImage(image) {
        this.setState({zoom: image});
    }

    render() {
        const cta = this.props.cta || 'More Photos';

        if (this.state.open === false) {
            if (this.props.component === 'button')
                return (<button className="bigBtn" onClick={e => this.setState({open: true})}>{cta}</button>);
            else return (<strong style={{cursor: 'pointer'}} onClick={e => this.setState({open: true})}>{cta}</strong>);
        }

        return (
            <Dialog open={this.state.open} onClose={e => this.setState({open: false})}
                    aria-labelledby="rent-gallery-form" maxWidth={'xl'} fullWidth={true}>
                <DialogTitle id="rent-gallery-form">

                    <Typography id="image-scaler" gutterBottom>
                        Image Height
                    </Typography>
                    <Slider
                        value={this.state.imageHeight}
                        aria-labelledby="image-scaler"
                        onChange={(e,val) => this.setState({imageHeight:val})}
                        valueLabelDisplay="auto"
                        step={25}
                        marks
                        min={100}
                        max={window.innerHeight}
                    />

                </DialogTitle>
                <DialogContent style={{padding:0}}>
                    {Object.keys(expandedDefault).map((name, sec) => {
                        let section = projects.children.find(o => o.name === name);
                        if (!section || !section.children || section.children.length === 0) return '';
                        return (
                            <Accordion key={`${sec}-gallery-acc`}
                                       expanded={this.state.expanded[section.name]}
                                       className={this.props.classes.accordion}
                                       onChange={() => {
                                           let obj = {...this.state.expanded};
                                           obj[section.name] = !obj[section.name];
                                           this.setState({expanded:obj})
                                       }}>
                                <AccordionSummary
                                    className={this.state.expanded[section.name] === true ? this.props.classes.accordionHead : ''}
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls={`${sec}-gallery-panel`}
                                    id={`${sec}-gallery-header`} >
                                    <Typography>{section.name.replace('_', ' ')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails id={`${sec}-gallery-panel`}>
                                    <Grid container justify={'center'}>
                                        {section.children.map((image) => {
                                            return <Grid item key={image.path} style={{height: this.state.imageHeight, padding: 1}}>
                                                <img onClick={e => this.zoomImage(image)} style={{height: '100%'}}
                                                     src={image.path} alt={image.name}/>
                                            </Grid>
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>)
                    })}
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Gallery);
