import React, {Component} from 'react';
import {Checkbox, Fab, Grid, Typography} from '@material-ui/core';
import CardHeader from "@material-ui/core/CardHeader";
import {withStyles} from "@material-ui/core/styles";
import styles from "../styles";


const RentalCategories = require('../data/elephante-upgrades.json');

class ElephanteUpgrades extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: {},
            total: 0,
        };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    }

    buildPrice() {
        let total = 0;
        for(let name in this.state.checkedItems) {
            total += this.state.checkedItems[name];
        }
        if (total > 0) {
            return <Fab size={'small'} style={{position:'fixed', bottom:8, right:8}} color={'primary'}>${total}</Fab>
        }
        return null;
    }


    handleCheckboxChange = (event, item) => {
        const { checked } = event.target;

        const st = {...this.state.checkedItems};

        if (checked === true) {
            st[item.name] = item.price;
        } else if (typeof st[item.name] === 'number'){
            delete st[item.name]
        }
        console.log(Object.keys(st));
        this.setState({checkedItems: st})
    };



    render() {
        return (
            <React.Fragment>


                <Grid container>
                    {RentalCategories.map((category) => (
                        <Grid item container key={category.category} className={this.props.classes.checkboxSection}>
                            <Typography variant="h4">
                                {category.category}
                            </Typography>
                            <Grid item container>
                                {category.items.map((item) => (
                                    <CardHeader
                                        component={Grid}
                                        item
                                        key={item.name}
                                        avatar={
                                            <Checkbox
                                                checked={typeof this.state.checkedItems[item.name] === 'number'}
                                                onChange={(e) => this.handleCheckboxChange(e, item)}
                                                color="primary"
                                            />
                                        }
                                        title={item.name}
                                        subheader={<React.Fragment>
                                            <small><strong>${item.price}</strong> / <small>{item.unit}</small></small>
                                        </React.Fragment>}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                {this.buildPrice()}
            </React.Fragment>

        );
    }
}

export default withStyles(styles)(ElephanteUpgrades);
