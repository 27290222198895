import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import {withSnackbar} from 'notistack';
import {Grid} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

// Example POST method implementation:
async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

class RentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: false,
            name: '',
            mail: '',
            start_date: '',
            duration: '',
            budget: '',
            aboutus: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        window.logEvent('page_view', {page_title: 'Rent'});
    }

    handleChange(e) {
        // console.log('setting ' + e.currentTarget.name + ' with ' + e.currentTarget.value);
        this.setState({[e.currentTarget.name]: e.currentTarget.value});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});

        const obj = {
            "mail": this.state.mail,
            "name": this.state.name,
            "field_start_date": this.state.start_date,
            "field_duration": this.state.duration,
            "field_budget": this.state.budget,
            "field_idea": this.state.aboutus
        };

        const that = this;
        postData('https://portal.ruhralfarms.com/application/new?_format=json', obj)
            .then(function (response) {
                console.log(response);
                that.props.enqueueSnackbar('Inquiry received.', {variant: 'success'});
                that.setState({loading: false, open: false});
            })
            .catch(function (error) {
                console.log(error);
                that.props.enqueueSnackbar(error.message, {variant: 'error'});
                that.setState({loading: false, open: false});
            });


        return false;
    }

    render() {
        const cta = this.props.cta || 'Inquire';

        if (this.state.open === false) {
            if (this.props.component === 'button')
                return (<button className="bigBtn" onClick={e => this.setState({open: true})}>{cta}</button>);
            else return (<strong style={{cursor: 'pointer'}} onClick={e => this.setState({open: true})}>{cta}</strong>);
        }

        return (
            <Dialog open={this.state.open} onClose={e => this.setState({open: false})}
                    aria-labelledby="rent-app-form" maxWidth={'lg'} fullWidth={true}>

                <form name="rent-app" method="POST">
                    <DialogTitle id="rent-app-form">Inquire</DialogTitle>
                    <DialogContent>
                        <Grid container justify={'space-around'} alignContent={'center'} spacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    required={true}
                                    autoComplete='true'
                                    type="text"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label='Email'
                                    name="mail"
                                    required={true}
                                    autoComplete='true'
                                    type="mail"
                                    value={this.state.mail}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justify={'space-around'} alignContent={'center'} spacing={1}>
                            <Grid item xs={4}>
                                <TextField
                                    name="budget"
                                    fullWidth
                                    label="Budget"
                                    type="number"
                                    value={this.state.budget}
                                    onChange={this.handleChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                        inputProps: {min: 3500, max: 6000, maxLength: 4}
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    InputLabelProps={{shrink: true}}
                                    label={"Ideal move-in date"}
                                    type="date"
                                    name="start_date"
                                    fullWidth
                                    value={this.state.start_date}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    select
                                    id={'duration'}
                                    label={'How many months?'}
                                    required={true}
                                    fullWidth={true}
                                    value={this.state.duration}
                                    onChange={e => this.setState({duration: e.target.value})}
                                    name={'duration'}
                                >
                                    <MenuItem disabled value={''}>Select ideal duration</MenuItem>
                                    <MenuItem value={0}>Less than 1 month</MenuItem>
                                    <MenuItem value={1}>1 month</MenuItem>
                                    <MenuItem value={2}>2 months</MenuItem>
                                    <MenuItem value={3}>3 months</MenuItem>
                                    <MenuItem value={4}>4 months</MenuItem>
                                    <MenuItem value={5}>5 months</MenuItem>
                                    <MenuItem value={6}>6 months</MenuItem>
                                    <MenuItem value={99}>More than 6 months</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <TextField
                            label="Tell us about yourself"
                            name="aboutus"
                            type="textarea"
                            required={true}
                            multiline
                            rows="3"
                            className="mt-5"
                            fullWidth
                            helperText="and ask any question about us"
                            value={this.state.aboutus}
                            onChange={this.handleChange}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={e => this.setState({open: false})} color="primary">
                            Cancel
                        </Button>
                        {this.state.loading === true ?
                            <CircularProgress color='secondary'/>
                            :
                            <Button type="submit" onClick={this.handleSubmit} color="primary" variant="contained"
                                    disabled={this.state.mail.length === 0 || this.state.aboutus.length === 0}>
                                Submit
                            </Button>
                        }
                    </DialogActions>
                </form>
            </Dialog>
        );
    }

}

export default withSnackbar(RentForm);
