import React from 'react';
import {AppBar, Grid, Toolbar, Typography} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import styles from "../styles";
import ElephanteUpgrades from "./ElephanteUpgrades";
import GalleryKH from "./GalleryKH";

const IMGDATA = require('../data/elephante.json');

class Elephante extends React.Component {

    constructor(p) {
        super(p);
        this.state = {preload: false, gallery: false};
    }

    componentDidMount() {
        if (document.getElementById('droneVid')) {
            document.getElementById('droneVid').playbackRate = 0.2
        }
    }

    toggleGallery() {
        this.setState({gallery: !this.state.gallery})
    }

    buildImageGrid(start, end) {
        const {classes} = this.props;
        const ctas = [];
        for (let i = start; i < end; i++) {
            const image = IMGDATA.children[0].children[i];
            let media = null;
            if (image.path.indexOf('.mp4') > 0) {
                media = <video src={image.path} poster={'/images/elephante/wide-open___thumbnail.jpg'}
                               controls={true} muted={true} autoPlay={true} loop={true} style={{height: 285,  width:'100%'}}/>
            } else {
                media = <img src={image.thumb || image.path} className={classes.imgFluid}
                             style={{cursor:'pointer'}}
                             alt={image.name.replace('_', ' ')}/>
            }
            ctas.push(<Grid item key={image.path} xs={12} sm={6} md={4}
                            className={classes.gridImg}
                            onClick={() => this.toggleGallery()}>
                    {media}
                    <div className={classes.overImgLabel}>{image.name.replace('_', ' ')}</div>
                </Grid>
            )
        }
        return <Grid container spacing={1} alignItems={'center'} alignContent={'center'} style={{margin:'10px 0'}}
                     justifyContent={'space-around'}>{ctas}</Grid>
    }

    render() {

        return (
            <div id='Elephante' style={{paddingBottom: 40}}>

                <AppBar position="relative" color={'secondary'}>
                    <Toolbar style={{justifyContent: 'space-between'}}>
                        <Grid></Grid>
                        <Typography variant="h5" style={{color: "#fff"}}>
                            <small>Low Key</small> <strong>Camper SUV</strong> on <em>Kauai</em>
                        </Typography>
                        <Typography variant="h6" style={{textAlign: 'right'}}>
                            <NavLink to={'/'}><img src='/icon.png' alt="logo" height='40'/></NavLink>
                        </Typography>
                    </Toolbar>
                </AppBar>

                {this.state.gallery && <GalleryKH projects={IMGDATA} expandedDefault={{
                    'photos': true
                }} onClose={() => this.toggleGallery()} />}

                <Grid container direction={'column'}>

                    <Grid item style={{textAlign: 'center', marginTop:20}}>
                        <Typography  variant={'body1'}>
                            Original All-Wheel Drive 2004 Honda Element</Typography>
                        <Typography variant={'body1'}>Swivel Seat, Suicide Doors, Tailgate & Hatchback
                        </Typography>
                        <Typography variant={'h5'} gutterBottom>Base $150 / Day</Typography>
                    </Grid>

                    {this.buildImageGrid(0, 2)}

                    {this.buildImageGrid(2, 4)}

                    {this.buildImageGrid(7, 9)}

                    <Grid item style={{padding: '0 3%'}}>
                        <Typography variant={'subtitle2'}>Includes</Typography>
                        <Typography component={'li'} variant={'body1'}>First  $100 of add ons below</Typography>
                        <Typography component={'li'} variant={'body1'}>
                            North Shore Pit Stop: <span component={'li'} variant={'body1'}>5 minutes to Moloaʻa / 10 minutes to Kilauea Town / 1 minute off highway</span>
                            <ul>
                            <Typography component={'li'} variant={'body1'}>
                                9am - 6pm daily shower & bathroom access
                            </Typography>
                            <Typography component={'li'} variant={'body1'}>
                                Unlimited toy swaps, assuming availability
                            </Typography>
                            </ul>
                        </Typography>

                        <Typography style={{textAlign: 'center'}}>
                            <a href={'https://docs.google.com/document/d/1wllzR67hSQNKFSMigMunGqfbekoVhbCaiETPxOr9Ji0/edit?usp=sharing'}
                               target={"_blank"}>Read Rental Contract</a>
                        </Typography>
                    </Grid>

                    {this.buildImageGrid(4, 7)}

                    <Grid item style={{padding: '0 3%'}}>
                        <ElephanteUpgrades/>
                    </Grid>


                    <Grid item style={{padding: '0 3%', marginTop:20}}>
                        <Typography variant={'body1'} gutterBottom>
                            Our chariot - Elephanté - has carried our family all around California and round trip to Mexico City for
                            9
                            months. It's earned a retirement on Kauai to sleep with you by the beach or up in the mountains of
                            Koke'e.
                            Camp in comfort with a well packed setup, of everything you want &amp; need to keep charged and
                            cruising.
                        </Typography>
                    </Grid>

                    {this.buildImageGrid(9, IMGDATA.children[0].children.length)}


                    <h5 style={{marginTop:30, textAlign:'center'}}>Email info@ruhralfarms.com with your dates and selections for a quote.</h5>

                </Grid>
            </div>
        );
    }
}


export default withStyles(styles)(Elephante);

