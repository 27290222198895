import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MyAccordion from "./MyAccordion";
import styles from "../styles";


class ByLaws extends React.Component {

    constructor(p) {
        super(p);
        this.state = {expandAll:false}
    }

    render() {
        const {classes} = this.props;
        return (
            <Grid container direction='column' spacing={0}>
                <FormControlLabel
                    style={{alignSelf:'flex-end'}}
                    control={
                        <Switch
                            checked={this.state.expandAll}
                            onChange={() => this.setState({expandAll:!this.state.expandAll})}
                            name="expandAll"
                            color="primary"
                        />
                    }
                    label="Expand All"
                />
                <Typography variant={'h5'} style={{textAlign:'center'}}>Cooperative Structure and Operation</Typography>
                <MyAccordion expanded={this.state.expandAll} title={"Voting"} section={1.1}>
                    <div>
                        <Typography>Voting will only be necessary for</Typography>
                        <ul className={classes.ul}>
                            <li>Adding or removing Members, Directors, or Officers</li>
                            <li>Change any Member's hourly rate for Patronage (wages)</li>
                            <li>Changing spending limits on unapproved vendors</li>
                            <li>Changing any part of the voting or decision making process</li>
                        </ul>
                    </div>
                </MyAccordion>
                <MyAccordion expanded={this.state.expandAll} title={"Members"} section={1.2}>
                    <div>
                        Everyone that is part of the Cooperative is considered a Member and can:
                        <ul>
                            <li>Earn wages as "Patronage Dividends" into their Member Accounts where at <em>least</em> 50% is paid out in cash annually</li>
                            <li>Earn interest as "Dividends" on the balance of their Member Accounts</li>
                            <li>Harvest personal amounts of select produce for daily meals</li>
                            <li>Vote on relevant issues as described by 1.1</li>
                        </ul>
                    </div>
                </MyAccordion>
                <MyAccordion expanded={this.state.expandAll} title={"Buyers"} section={1.3}>
                    <ul>
                        <li>Responsible for purchases and cosignment agreements with others farmers and vendors whose products would be sold through the Cooperative's Markets.</li>
                        <li>Buyers are responsible for making purchases at a price that allows the Cooperative to resell at the lowest available price on the market and earn optimal profits for the Cooperative.</li>
                        <li>Market placement should be given to Member's own products, so long as the purchase price is optimal for the Cooperative</li>
                    </ul>
                </MyAccordion>
                <MyAccordion expanded={this.state.expandAll} title={"Secretary"} section={1.4}>Responsible for filing the Cooperatives’ records, reports, notices, and meeting minutes</MyAccordion>
                <MyAccordion expanded={this.state.expandAll} title={"Chief Financial Officer"} section={1.5}>Responsible for the annual report and ongoing financial reporting and transparency.</MyAccordion>
                <MyAccordion expanded={this.state.expandAll} title={"Chief Operations Officer"} section={1.6}>Responsible for scheduling staff and labor needed for all operations and maintenance of Cooperative businesses.</MyAccordion>
                <MyAccordion expanded={this.state.expandAll} title={"President"} section={1.7}>
                    <ul>
                        <li>Responsible for internal communication among the membership as well as internal conflict resolution that requires moderation but not voting.</li>
                        <li>The president is also responsible for all duties of the Secretary if the Secretary is unable or unwilling to do so.</li>
                    </ul>
                </MyAccordion>

                <Typography variant={'h5'} style={{textAlign:'center', margin:'20px 0 5px 0'}}>Membership & Decision Making</Typography>

                <MyAccordion expanded={this.state.expandAll} title={"Membership Requirements"} section={2.1}>
                    <div>
                        <ul className={classes.ul}>
                            <li>Submit a membership application to Secretary</li>
                            <li>Be approved by 85% of the Members</li>
                            <li>Pay an Initial Capital Contribution. If not stated in the application, the amount will be determined by CFO</li>
                            <li>300 Work Hours or 6 months labor whichever is longer</li>
                        </ul>
                        <blockquote className={classes.blockquote}>
                            For founding Board of Directors, we should each pitch in at least $2,500 or the equivalent in work hours at our agreed rate for our Initial Capital Contribution.
                        </blockquote>
                    </div>
                </MyAccordion>
                <MyAccordion expanded={this.state.expandAll} title={"Expulsion of a Member"} section={2.2}>
                    <div>
                        <Typography gutterBottom={true} variant={'body1'}>No Member may be expelled or suspended except according to procedures satisfying the requirements of this section:</Typography>
                        <ul className={classes.ul}>
                            <li>A Member may, for any lawful reason, be expelled from the Cooperative by a vote of 76% or greater of the Members at a duly called meeting at which a quorum is present.</li>
                            <li>The Member must be given 15 days’ prior notice of the expulsion, suspension, or termination, and the reasons for that expulsion, suspension, or termination.</li>
                            <li>The Member shall have an opportunity to be heard, orally or in writing, not less than five days before the effective date of expulsion, suspension, or termination, by a person or body authorized to decide that the proposed expulsion, termination, or suspension not take place.</li>
                            <li>Any notice required under this section may be given by any method reasonably calculated to provide actual notice.  Any notice given by mail must be given by first-class or registered mail sent to the last address of the Member shown on the Cooperative’s records.</li>
                            <li>A Member who is expelled or suspended shall be liable for any charges, dues, or other obligations incurred before the expulsion, suspension, or termination.</li>
                            <li>The Cooperative may direct a Member whose expulsion is being considered to refrain from conducting business as a Member until the expulsion decision is made, provided the Cooperative pays the Member her or his average weekly wage or compensation – calculated based on the three months preceding the date of the notice given pursuant to this section – until the expulsion decision is made.  The Cooperative may also direct a Member whose expulsion is being considered to stay away from the Cooperative’s places of business except as necessary to exercise her or his rights under law</li>
                        </ul>
                    </div>
                </MyAccordion>

                <MyAccordion expanded={this.state.expandAll} title={"Decision Making Process"} section={2.3}>
                    <ul className={classes.ul}>
                        <li>Matters will be discussed with the goal of reaching consensus.</li>
                        <li>If consensus cannot be reached, Members will vote on whether the issue must be decided at the current meeting or can be tabled for future discussion.</li>
                        <li>If at least three-fourths of the quorum believe that an immediate decision is needed, voting will be held on proposals regarding the issue.</li>
                        <li>The proposals can then be carried by a three-fourths vote, except as otherwise provided in these bylaws.</li>
                    </ul>
                </MyAccordion>
                <MyAccordion expanded={this.state.expandAll} title={"Kapuna Hale Estate"} section={2.4}>
                    <div>
                        Kapuna Hale will offer the Cooperative everything possible so long as the valuation of the estate grows alongside the Cooperatives efforts. Such support includes:
                        <ul className={classes.ul}>
                            <li>Free access to the property for farming and business development, as permitted by Law and these ByLaws</li>
                            <li>Priority housing to Members at a fair market value</li>
                        </ul>
                        <ul className={classes.ul}>
                            <li>Kapuna Hale must renew it's lease to the Cooperative every 6 months</li>
                            <li>The Cooperative Members may vote to relocate our primary place of business at any time</li>
                        </ul>
                        <blockquote className={classes.blockquote}>
                            <p>As Cooperative Members as well, Gene and Eli Taylor, will invest in the Cooperative's businesses through their residental rental income earned on the estate.</p>
                        </blockquote>
                    </div>
                </MyAccordion>

                <Typography variant={'h5'} style={{textAlign:'center', margin:'20px 0 5px 0'}}>Financial Provisions</Typography>

                <MyAccordion expanded={this.state.expandAll} title={"Definitions"} section={3.1}>
                    <ul className={classes.ul}>
                        <li>"Fiscal year" of the Cooperative shall be January 1st through December 31st.</li>
                        <li>"Surplus" shall be defined as the excess of revenues over Expenses for a fiscal year attributable to Member labor.</li>
                        <li>"Profit" shall be defined as the excess of revenues over Expenses for a fiscal year attributable to non-Member labor.</li>
                        <li>"Loss" shall be defined as the excess of Expenses over revenues for a fiscal year.</li>
                        <li>Surplus, Profit, and Loss shall be determined on a tax basis.  Surplus and Profit shall not include cash contributions by Members to capital.</li>
                        <li>"Expenses" shall include Distributions paid pursuant to Section 7.6, payments of any interest and principal on any debts of the Cooperative, and reasonable reserves as determined by the Board of Directors.</li>
                        <li>The "Collective Account" shall be Surplus, Profit, and reserves that are retained in the Cooperative and not distributed to Members.</li>
                        <li>"Patronage" shall be defined as hours worked by each Member for the Cooperative.</li>
                        <li>"Patronage Dividends" shall have the definition contained in Internal Revenue Code Section 1388(a) (dividends paid to Members based on Patronage).</li>
                        <li>"Member Account" shall be defined as each Member’s capital account in the Cooperative (initial capital contribution plus written notices of allocation minus Distributions minus Losses plus/minus any other item that affects the balance in the Member’s capital account).</li>
                        <li>"Distribution" means the distribution of interest on capital contributed, but does not include Patronage Dividends.</li>
                    </ul>
                </MyAccordion>

                <MyAccordion expanded={this.state.expandAll} title={"Collateral Assets"} section={3.2}>
                    <div>
                        <ul className={classes.ul}>
                            <li>The Cooperative will own all facilities, equipment and assets it builds or purchases, along with all produce and lifestock grown or purchased</li>
                            <li>Ownership in the Coop is not ownership in the Kapuna Hale estate.</li>
                            <li>Any permanent structures built into the land at Kapuna Hale will not behold the estate from it's authority to use such assets and leverage their value as collatoral</li>
                            <li>The Cooperative gives Kapuna Hale <em>Right of First Refusal</em> on all assets to be sold with a holding period of 6 months</li>
                        </ul>
                        <blockquote className={classes.blockquote}>
                            Buried power and water lines will have to be be owned by the estate eitherway.
                        </blockquote>
                    </div>
                </MyAccordion>

                <MyAccordion expanded={this.state.expandAll} title={"Allocations"} section={3.3}>
                    <ul className={classes.ul}>
                        <li>Any Profit shall be credited to the Collective Account.</li>
                        <li>Any Surplus shall be credited to the Collective Account as necessary to bring the year’s contribution to the Collective Account up to 25% of the year’s combined Profit/Surplus.  All other Surplus shall be paid as Patronage Dividends in direct proportion to Patronage during the fiscal year.</li>
                        <li>Any Loss shall be allocated 75% to Member Accounts in direct proportion to Patronage during the fiscal year and 25% to the Collective Account, with the exception of Losses occurring and/or carried over from the Cooperative’s first two fiscal years, which shall be allocated 100% to the Collective Account.</li>
                        <li>The percentages referred to in this section can be changed for a coming fiscal year by the Board.</li>
                    </ul>
                </MyAccordion>

                <MyAccordion expanded={this.state.expandAll} title={"Patronage Dividends"} section={3.4}>
                    <ul className={classes.ul}>
                        <li>Patronage Dividends shall be made 50% in cash and 50% to each individual Member Account as a written notice of allocation, unless different proportions are approved by the Board within eight-and-a-half months of the fiscal year’s close – however, at least 20% must be distributed in cash.</li>
                        <li>Patronage Dividends may be by qualified or non-qualified written notices of allocation or a combination of the two.</li>
                    </ul>
                </MyAccordion>

                <MyAccordion expanded={this.state.expandAll} title={"Distributions of Interest on Member Accounts"} section={4.4}>
                    <ul className={classes.ul}>
                        <li>The Cooperative may, by a decision of the Board, pay interest to Members on the Members Accounts.</li>
                        <li>The interest may be paid in cash or as an additional credit to the  Member Accounts. </li>
                        <li>The rate of interest shall be determined by the Board, but may not, in one year, exceed  15 percent of each Member’s contributed capital, which includes capital contributions, membership fees, and capital credits.</li>
                    </ul>
                </MyAccordion>

                <MyAccordion expanded={this.state.expandAll} title={"Periodic Redemption of Member Accounts"} section={4.5}>
                    <ul className={classes.ul}>
                        <li>The Cooperative shall aim to pay out in cash to the Members all funds credited to their Member Accounts within three years of the date they were first credited.</li>
                        <li>As a general rule, written notices of allocation credited to Member Accounts (including notices now converted to debt) will be paid out in the order in which they are credited, with the oldest paid out first. However, the Board can decide to accelerate the repayment of debt owed to former Members on a case-by-case basis.</li>
                        <li>If the Cooperative does not have sufficient funds to pay out all funds credited to Member Accounts for a given fiscal year, then funds will be paid out in proportion to the balance in the Member Accounts.</li>
                    </ul>
                </MyAccordion>

                <MyAccordion expanded={this.state.expandAll} title={"Payment Rights Upon Membership Termination."} section={4.6}>
                    <ul className={classes.ul}>
                        <li>When a Membership is terminated for any reason, including a Member’s death, the amount in the Member Account will automatically be converted to debt owed to the former Member, or, if necessary, to the Member’s estate, or to another assignee designated by the Member.</li>
                        <li>The Cooperative shall repay the debt within five years of the Membership termination, with interest accruing at the discount rate – as set by the Federal Reserve Bank of San Francisco – plus two percent, on the amount outstanding at the end of each fiscal year.</li>
                        <li>The Cooperative, in settling a Member Account, shall have the right to set off any and all indebtedness of the former Member to the Cooperative.</li>
                    </ul>
                </MyAccordion>

                <MyAccordion expanded={this.state.expandAll} title={"Priority of Payments"} section={4.7}>
                    <div>
                        Notwithstanding anything else to the contrary in this Article, payments by the Cooperative shall be made in the following order of priority:
                        <ul className={classes.ul}>
                            <li>First, to make payments of any necessary expenses related to the operation of the cooperative, including wages, and payments of any interest and principal on any debts of the Cooperative,</li>
                            <li>Second, to pay Patronage Dividends to all eligible Members,</li>
                            <li>Third, to pay Distributions to all eligible Members, and</li>
                            <li>Fourth, to make periodic redemptions pursuant to Section 7.7.</li>
                        </ul>
                    </div>
                </MyAccordion>

                <MyAccordion expanded={this.state.expandAll} title={"Dissolution Distributions"} section={4.8}>
                    <ul className={classes.ul}>
                        <li>Upon liquidation, dissolution, or sale of the assets of the Cooperative, any assets left after payment of all debts and Member Account balances shall be distributed to all persons who are current or living past Members in proportion to the number of hours each Member worked during the time he or she was a Member of the Cooperative.</li>
                        <li>No distribution need be made to any person who fails to acknowledge the receipt of notice of liquidation in a timely manner.  Said notice shall be deemed sufficient if sent by certified mail, at least 30 days before distribution of any residual assets, to the person’s last known business or residence address.</li>
                    </ul>
                </MyAccordion>

                <MyAccordion expanded={this.state.expandAll} title={"Member Rights to Inspect Records"} section={4.9}>
                    <ul className={classes.ul}>
                        <li>The Cooperative’s Bylaws and Articles shall be open to inspection by the Members at all reasonable times during office hours.</li>
                        <li>Any such inspection may be made in person or by agent or attorney and the right of inspection includes the right to copy and make extracts.</li>
                        <li>The accounting books and records and minutes of proceedings of the Members and the Board and committees of the Board shall be open to inspection upon the written demand on the Cooperative of any Member at any reasonable time, for a purpose reasonably related to such person’s interests as a Member.</li>
                        <li>Every Director shall have the absolute right at any reasonable time to inspect and copy all books, records and documents of every kind and to inspect the physical properties of the Cooperative.</li>
                    </ul>
                </MyAccordion>

                <MyAccordion expanded={this.state.expandAll} title={"Annual Report"} section={4.10}>
                    <ul className={classes.ul}>
                        <li>The annual report shall be prepared no later than 120 days after the close of the Cooperative’s fiscal year and shall be distributed to Members at or before their next meeting.</li>
                        <li>The annual report shall contain in appropriate detail:</li>
                        <li>A balance sheet as of the end of the fiscal year;</li>
                        <li>An income statement;</li>
                        <li>A cash flow statement of the fiscal year;</li>
                        <li>A statement of where the names and addresses of current Members are located; and</li>
                        <li>An annual statement of transactions and indemnifications to “interested persons” as defined by law.</li>
                        <li>For fiscal years in which the Cooperative has (at any given time) over 25 Members, the Cooperative shall notify each Member of his/her right to receive an annual financial report.</li>
                        <li>The annual report shall be accompanied by any pertinent report by independent accountants.</li>
                        <li>If there is no such report from an independent accountant, an authorized Officer of the Cooperative shall certify that the annual report was prepared from the books and records of the Cooperative, without audit.</li>
                    </ul>
                </MyAccordion>

            </Grid>

        );
    }
}

export default withStyles(styles)(ByLaws);

