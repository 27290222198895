import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {AppBar, Box, Button, Grid, Toolbar, Typography} from "@material-ui/core";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import RentForm from "./RentForm";
import {NavLink} from "react-router-dom";
import Gallery from "./Gallery";
import styles from "../styles";

const projects = require('../data/gardenhwy.json');

class GardenHwy extends React.Component {

    constructor(p) {
        super(p);
        this.state = {preload: false};
    }

    render() {
        const {classes} = this.props;

        const ctas = [];
        Object.values(projects.children).forEach((section, sec) => {
            if (section.children && section.children.length > 0) {
                ctas.push(<GridListTile key={section.children[0].path}
                                        onClick={() => this.setState({preload: section.name})}>
                        <img src={section.children[0].thumb || section.children[0].path}
                             alt={section.name.replace('_', ' ')}/>
                        <GridListTileBar
                            title={section.name.replace('_', ' ')}
                            classes={{
                                root: classes.gridListTitleBar,
                                title: classes.gridListTitle,
                            }}
                        />
                    </GridListTile>
                )
            }
        })


        return (
            <div id='gardenhwy' style={{paddingBottom: 40}}>
                <AppBar position="relative" color={'secondary'}>
                    <Toolbar style={{justifyContent:'space-between'}} >
                        <NavLink to={'/workspaces'}><Button>Go Back</Button></NavLink>
                        <Typography variant="overline" style={{color: "#fff"}}>
                            FOR RENT / SACRAMENTO, CALFORNIA / ANY 1-6 MONTHS
                        </Typography>
                        <Typography variant="h6" style={{textAlign: 'right'}}>
                            <NavLink to={'/'}><img src='/icon.png' alt="logo" height='40'/></NavLink>
                        </Typography>
                    </Toolbar>
                </AppBar>

                <GridList className={classes.gridList} cols={window.innerWidth > 500 ? 5.5 : 3.5} style={{maxWidth: 1000, margin: '20px auto 0 auto'}}>
                    {ctas}
                </GridList>

                <Box mt={3} style={{textAlign: 'center'}}>
                    <Typography variant={'h5'}>2 Stories / 2 Bedrooms / 2 Full Baths / 2000 ft.<sup>2</sup> on 12 ft.
                        stilts</Typography>
                    <Typography variant={'h5'}>1 Acre / Riverfront / Pool / Gym / Fruit Trees / Fire pit / Slide /
                        Swings
                        / Space</Typography>
                    <Typography variant={'overline'}>$4,500 - $5,500 / month</Typography>
                </Box>

                <section>
                    <Grid container style={{margin: '40px auto', textAlign: 'center'}} justify={'space-between'}
                          alignContent={'center'} alignItems={'center'}>
                        <Grid item xs={6}>
                            <RentForm cta={'Inquire'} component='button'/>
                        </Grid>
                        <Grid item xs={6}>
                            <Gallery cta={'Photos'} component='button' preload={this.state.preload}/>
                        </Grid>
                    </Grid>

                    <Typography variant={'h5'} gutterBottom>LOCATION</Typography>
                    <ul>
                        <Typography component={'li'} variant={'body1'}>Garden Hwy, Sacramento, California</Typography>
                        <Typography component={'li'} variant={'body1'}>20 minutes to Downtown Sacramento</Typography>
                        <Typography component={'li'} variant={'body1'}>90 minutes to San Francisco or Napa</Typography>
                        <Typography component={'li'} variant={'body1'}>120 minutes to South Lake Tahoe</Typography>
                        <Typography component={'li'} variant={'body1'}>270 minutes to Yosemite</Typography>
                    </ul>

                    <Typography variant={'h5'} gutterBottom>DREAMS</Typography>
                    <ul>
                        <Typography component={'li'} variant={'body1'}>Rotate our residence here every 2-6
                            months</Typography>
                        <Typography component={'li'} variant={'body1'}>With 1 - 2 other families through the
                            seasons.</Typography>
                    </ul>

                    <Typography variant={'h5'} gutterBottom>STORY</Typography>
                    <Typography variant={'body1'} gutterBottom>
                        We discovered this slice of heaven when escaping quarantine last year.
                        We fell in love with warm river, lush land, and friendly neighbors. So we want to find the right couple or family to
                        partner with and keep it as a part-time home base.
                    </Typography>
                    <Typography variant={'body1'} gutterBottom>
                        The home is nestled between acres of rice fields and the Sacramento river. It features a private
                        swimming pool, an elevator, a dumbwaiter, and a wrap-around deck surrounded by
                        Oak trees overlooking the river.
                    </Typography>
                    <Typography variant={'body1'} gutterBottom>
                        We've furnished the home out with everything you could need to work and play at home - ergonomic
                        desks,
                        stair master, boxing bag, a grill, a fire pit, hammock swings overlooking the river,
                        a slide going into the river, and stairs coming up from it so you can go back down again.
                    </Typography>
                    <Typography variant={'body1'} gutterBottom>
                        Not to mention a resident sea lion, some beavers, otters, owls, bats, and most recently a
                        mountain lion that didn’t hurt anyone.
                        Dragon flies make an appearance in the summer because of the rice fields and like to circle the
                        dome around the summertime.
                        Butterflies and hummingbirds everywhere everyday…just giving the details because that’s where
                        the magic lies.
                    </Typography>

                    <Typography variant={'h5'} gutterBottom>TERMS</Typography>
                    <ul>
                        <Typography component={'li'} variant={'body1'}>$4,500 - $5,500 / month + deposit depending on
                            your dates</Typography>
                        <Typography component={'li'} variant={'body1'}>High Speed Internet and Gas utilities covered by us</Typography>
                        <Typography component={'li'} variant={'body1'}>First $150 in Electricity covered by us, then it's on you to watch your AC use in the Summer.</Typography>
                        <Typography component={'li'} variant={'body1'}>Water, Garage, and Landscaping is covered by our Landlords who live two doors down</Typography>
                        <Typography component={'li'} variant={'body1'}>Replace what you use and clean up art
                            projects</Typography>
                        <Typography component={'li'} variant={'body1'}>Our neighbors occasionally use the pool in the
                            summer, and maintain it year round. With the right attitude, you'll find many shared benefits from the open and fenceless community here.</Typography>
                    </ul>

                    <Typography variant={'h5'} gutterBottom>AMENITIES</Typography>
                    <ul>
                        <Typography component={'li'} variant={'body1'}>Art: Pottery Wheel, Painting easels and brushes, Wood working tools, Sewing machine and dress form</Typography>
                        <Typography component={'li'} variant={'body1'}>Adventure: Slide, Bike, pool, water toys</Typography>
                        <Typography component={'li'} variant={'body1'}>Exercise: 6 ft 120 lb bag, Bowflex, Treadmill, StairMaster, Pool, River, and lots of exercise equipment and yoga mats</Typography>
                        <Typography component={'li'} variant={'body1'}>Kitchen: more appliances than is</Typography>
                        <Typography component={'li'} variant={'body1'}>Workspaces: 2 TVs, 5 monitors spread across 3 desks with adjusted heights for tables and chairs</Typography>
                        <Typography component={'li'} variant={'body1'}>Babies: Crib, bassinet, Play stuff, Seats</Typography>
                        <Typography component={'li'} variant={'body1'}>Access: Elevator and dumbwaiter to upper deck.</Typography>
                        <Typography component={'li'} variant={'body1'}>Transportation: We have two cars (A3 Audi e-tron and Honda Element) we can rent out separately</Typography>
                    </ul>


                    <Typography variant={'h5'} gutterBottom>ABOUT US</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={3} ><img src={'/images/equipo-eli-sammie.jpeg'} alt={'team'} style={{width:'100%', marginTop:5}} /></Grid>
                        <Grid item xs={9} >
                            <Typography variant={'body1'} gutterBottom>
                            We are a couple who had our first baby November 2020.
                            We used to live very nomadically prior to the quarantine and travel shutdowns,
                            and are looking to slowly resume our lifestyle again with this new baby in tow.
                            </Typography>
                            <Typography variant={'body1'} gutterBottom>
                            We love nature, good food, travel, art, and overall live our life with a passion for creativity, and our home reflects this.
                            If you are an artist / woodworker / craftsman you will LOVE our home.
                            </Typography>
                            <Typography variant={'body1'} gutterBottom>
                            We've got carpentry tools, painting supplies, a sewing machine, and a pottery wheel. We are even open to sharing some clay that you can throw down with
                            </Typography>
                            <Typography variant={'body1'} gutterBottom>
                            We are open to home swaps in Mexico and Kauai.
                            </Typography>
                        </Grid>
                    </Grid>

                </section>
            </div>
        );
    }
}


export default withStyles(styles)(GardenHwy);

