import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import styles from "../styles";

const SLIDES = [
    {
        vid: '/images/kh/satelitte-drone-loop.mp4',
        img: '/images/kh/satellite-gearth-above-cropped.jpg',
        label: 'Land',

    },
    {
        img: '/images/coopplans/coopplan-v2.jpg',
        label: 'Facilities & Crops',
    },
    {
        img: '/images/coopplans/coopplan-v2-inspiration.jpg',
        icon: '/images/kh/inspiration-icon.png',
        label: 'Inspiration',
    },
    {
        img: '/images/coopplans/profitability-sheet-instructions.png',
        icon: '/images/coopplans/profitability-sheet-instructions.png',
        label: 'Projections'
    }
]

const COLORS = {crops:'rgba(255,145,0,0.5)', facilities:'rgba(18,130,18,0.5)', utilities:'rgba(25,63,204,0.5)', terraform:'rgba(204,27,162,0.5)'}

class LandPlanSlideshow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {slideIndex: 2, enabled:{crops:true, facilities:true, utilities:true, terraform:true}}
        this.toggleLayer = this.toggleLayer.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(slideIndex) {
        this.setState({slideIndex: slideIndex}, () => {
            if (document.getElementById('droneVid')) {
                document.getElementById('droneVid').playbackRate = 0.3
            }
        });
    }

    toggleLayer(layer) {
        const st = {...this.state.enabled};
        st[layer] = !this.state.enabled[layer]
        this.setState({enabled:st});
    }

    render() {
        const {classes} = this.props;

        const overlays = [];
        let baseFileName = '/images/coopplans/coopplan-v2-';
        let legend = [];

        if (this.state.slideIndex === 1 || this.state.slideIndex === 2) {
            for (let menuItem in this.state.enabled) {

                legend.push(
                    <Button
                        style={{backgroundColor:COLORS[menuItem], color:'#fff', justifyContent:'space-between', textTransform:'capitalize'}}
                        variant={'contained'}
                        onClick={() => this.toggleLayer(menuItem)}
                        startIcon={<Checkbox color={'default'} checked={this.state.enabled[menuItem] === true} />}
                    >{menuItem}</Button>)

                if (this.state.enabled[menuItem] === true) {
                    overlays.push(<img src={`${baseFileName}${menuItem}.png`}
                                       key={`${baseFileName}${menuItem}.png`}
                                       alt={menuItem}
                                       style={{zIndex:overlays.length + 1}}
                                       className={classes.imgFluidOverlays}/>)
                    if (this.state.slideIndex === 2 && (menuItem === 'crops' || menuItem === 'facilities')) {
                        overlays.push(<img src={`${baseFileName}${menuItem}-inspiration.png`}
                                           key={`${baseFileName}${menuItem}-inspiration.png`}
                                           alt={menuItem}
                                           style={{zIndex:overlays.length + 2}}
                                           className={classes.imgFluidOverlays}/>)
                    }
                }
            }
        }

        if (legend.length > 0) {
            if (window.innerWidth > 700) {
                legend = <div style={{position:'absolute', zIndex:100, bottom:122, right:16, width:130}}>
                    <ButtonGroup orientation="vertical" size={'small'} fullWidth={true}>
                        {legend}
                    </ButtonGroup>
                </div>;
            } else {
                legend = <div style={{position:'relative', zIndex:100, width:'100%'}}>
                    <ButtonGroup orientation="vertical" size={'small'} fullWidth={true}>
                        {legend}
                    </ButtonGroup>
                </div>;
            }
        }

        return (
            <Grid container>
                <Grid item xs={12}>
                    <ButtonGroup color="primary" variant={'contained'} fullWidth={true} aria-label="Roadmap Slideshow"
                                 size={'small'} disableElevation={true}>
                        {SLIDES.map((slide, i) => {
                            return <Button
                                key={'slide' + i}
                                startIcon={slide.icon ?
                                    <img src={slide.icon} alt={slide.label} className={classes.imgFluid}
                                         height={35}/> : undefined}
                                color={this.state.slideIndex === i ? 'secondary' : 'primary'}
                                style={{fontWeight: 800, color: '#ffffff'}}
                                onClick={() => this.handleClick(i)}>{slide.label}</Button>
                        })
                        }
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                    {this.state.slideIndex === 0 &&
                        <React.Fragment>
                            <video id={'droneVid'} src={SLIDES[this.state.slideIndex].vid}
                                   poster={SLIDES[this.state.slideIndex].img}
                                   controls={true} muted={true} autoPlay={true} loop={true}
                                   className={classes.imgFluid}/>
                            <p style={{textAlign: 'center', fontWeight: 800}}>
                                <a href={"https://docs.google.com/spreadsheets/d/1nVtyOy512Z-0WzY1O_27MVodojgPqkOIwEr5KlQvNiQ/edit?usp=sharing#gid=500918714"}
                                   target={"_blank"}>Open Materials List</a>
                            </p>
                        </React.Fragment>
                    }
                    {this.state.slideIndex === 3 &&
                        <React.Fragment>
                            <img src={SLIDES[this.state.slideIndex].img} alt={SLIDES[this.state.slideIndex].label}
                                 className={classes.imgFluid}/>
                            <p style={{textAlign: 'center', fontWeight: 800}}>
                                <a href={"https://docs.google.com/spreadsheets/d/1VNgt1X4sz3XTXeGeHwCwSsPJwdynOaZILPrViCz0c24/edit#gid=1900901915"}
                                   target={"_blank"}>Open Projections Spreadsheet</a>
                            </p>
                        </React.Fragment>
                    }
                    {(this.state.slideIndex === 1 || this.state.slideIndex === 2) &&
                        <React.Fragment>
                            {legend}
                            <div style={{width:'100%', position:'relative'}}>
                            <img style={{zIndex:1}} src={`${baseFileName}empty.jpg`} className={classes.imgFluid}/>
                            {overlays}
                            </div>
                            <p style={{textAlign: 'center', fontWeight: 800}}>
                                <a href={"https://docs.google.com/spreadsheets/d/1nVtyOy512Z-0WzY1O_27MVodojgPqkOIwEr5KlQvNiQ/edit?usp=sharing#gid=500918714"}
                                   target={"_blank"}>Open Materials List</a>
                            </p>
                        </React.Fragment>
                    }
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(LandPlanSlideshow);

