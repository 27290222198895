import React from 'react';
import {AppBar, Box, Button, Grid, Toolbar, Typography} from "@material-ui/core";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { NavLink} from "react-router-dom";
import GalleryKH from "./GalleryKH";
import {withStyles} from "@material-ui/core/styles";
import styles from "../styles";

const projects = require('../data/kapunahale.json');
projects.children.forEach(section => {
    if (section.children) {
        section.children.sort((a,b) => new Date(a.time).getTime() - new Date(b.time).getTime())
    }
})

let expandedDefault = {
    'halekapuna': true
}


class HaleTrade extends React.Component {

    constructor(p) {
        super(p);
        this.state = {preload: false, gallery:false};
    }

    componentDidMount() {
        if (document.getElementById('droneVid')) {
            document.getElementById('droneVid').playbackRate = 0.2
        }
    }

    toggleGallery() {
        this.setState({gallery:!this.state.gallery})
    }

    render() {
        const {classes} = this.props;

        const ctas = [];
        Object.values(projects.children).forEach((section, sec) => {
            if (section.children && section.children.length > 0) {
                ctas.push(<GridListTile key={section.children[0].path}
                                        style={{width:'25%'}}
                                        onClick={() => this.toggleGallery(section.children[0].path)}>
                        <img src={section.children[0].thumb || section.children[0].path}
                             alt={section.name.replace('_', ' ')}/>
                        <GridListTileBar
                            title={section.name.replace('_', ' ')}
                            classes={{
                                root: classes.gridListTitleBar,
                                title: classes.gridListTitle,
                            }}
                        />
                    </GridListTile>
                )
            }
        })

        return (
            <div id='HaleTrade' style={{paddingBottom: 40}}>

                {this.state.gallery && <GalleryKH projects={projects} expandedDefault={expandedDefault} onClose={() => this.toggleGallery()} />}

                <AppBar position="relative" color={'secondary'}>
                    <Toolbar style={{justifyContent: 'space-between'}}>
                        <NavLink to={'/workspaces'}><Button>Go Back</Button></NavLink>
                        <Typography variant="overline" style={{color: "#fff"}}>
                            <strong>HOUSE SWAP</strong> OFFER / Kilauea, Kauai / ANY 1-6 MONTHS
                        </Typography>
                        <Typography variant="h6" style={{textAlign: 'right'}}>
                            <NavLink to={'/'}><img src='/icon.png' alt="logo" height='40'/></NavLink>
                        </Typography>
                    </Toolbar>
                </AppBar>

                <GridList style={{margin: '20px auto 0 auto'}}>
                    {ctas}
                </GridList>
                <section>

                    <Box mt={3} style={{textAlign: 'center'}}>
                        <Typography variant={'h5'}>6 Acres / Gardens / Trees / Pottery / Carpentry / Fitness / Arial Silk / Ceremony</Typography>
                        <Typography variant={'overline'}>Trade / $1,500 - $3,500 per month</Typography>
                    </Box>

                    <Typography variant={'h5'} gutterBottom>OUR LOCATION</Typography>
                    <ul>
                        <Typography component={'li'} variant={'body1'}>Waipake, Kilauea, Kauai, Hawaii</Typography>
                        <Typography component={'li'} variant={'body1'}>5 minutes to Moloaʻa Bay</Typography>
                        <Typography component={'li'} variant={'body1'}>1 minute to highway</Typography>
                    </ul>

                    <Typography variant={'h5'} gutterBottom>OUR DREAMS</Typography>
                    <ul>
                        <Typography component={'li'} variant={'body1'}>Rotate our residence here every 1-6
                            months</Typography>
                        <Typography component={'li'} variant={'body1'}>With 1 - 2 other families through the
                            seasons.</Typography>
                    </ul>

                    <br />
                    <video id={'droneVid'} src={'/images/kh/satelitte-drone-loop.mp4'}
                           poster={'/images/kh/satellite-gearth-above-cropped.jpg'}
                           controls={true} muted={true} autoPlay={true} loop={true}
                           className={classes.imgFluid} />
                    <br />

                    <Typography variant={'h5'} gutterBottom>OUR STORY</Typography>
                    <Typography variant={'body1'} gutterBottom>
                        We are reaching out to manifest the best of both worlds: <strong>Kauai</strong> and <strong>Oahu</strong>.
                    </Typography>
                    <Typography variant={'body1'} gutterBottom>
                        Our home on Kauai is situated in the center of 6 lush acres, bordered by mahogany forests
                        and a 600 acre cattle ranch of rolling pasture.
                        The home is 2400<sup>sqft</sup> with the perfect layout for community and privacy.
                    </Typography>
                    <Typography variant={'body1'} gutterBottom>
                        This <a
                        href={"https://drive.google.com/drive/folders/18jgTqJeg1zBus3tEkiK6YgLLale3MRso?usp=sharing"}
                        target={"blank"}>Google Drive</a> shows photos of each room and unit. Most furniture
                        has changed since these we taken, but check out the folder "2023" for more recent pictures.
                    </Typography>
                    <Typography variant={'body1'} gutterBottom>
                        <ul>
                        <li>Our easiest exchange would be for the 1200<sup>sqft</sup> 2 bed / 1 bath suite. You'd also have access to the main kitchen, dining room, lanai and all 6 acres.</li>
                        <li>For the more modest exchanges, our 400<sup>sqft</sup> tiny home sits on the highest ground in the area, while still offering privacy, parking and natural all around.
                        </li>
                        <li>Or we have 300<sup>sqft</sup> bedroom above the main kitchen with amazing views of the land and horizon.</li>
                        <li>For the more elaborate exchange, you can take over the whole home privately, as long as you can share the surrounding grounds with our 2 wonderful caregivers of the land.</li>
                        </ul>
                    </Typography>
                    <Typography variant={'body1'} gutterBottom>
                        Currently we host a preschool from 9-1pm Wednesday, Thursday and Fridays. As well we have a
                        40'
                        diameter wedding tent for yoga, ceremony, and feel-good events. We have a few small gardens,
                        13 coconut trees with many more fruit trees growing. For the craftsfolks, our garage is a fully equipped carpentry / workshop.
                    </Typography>
                    <Typography variant={'body1'} gutterBottom>
                        The trade would also include a Honda Element or Toyota Tundra.
                    </Typography>
                    <Typography variant={'body1'} gutterBottom>
                        If any of this sounds like a good fit for you, let's talk more: <a href={"mailto:info@ruhralfarms.com"}>info@ruhralfarms.com</a>
                    </Typography>
                    <br />

                    <Typography variant={'h5'} gutterBottom>ABOUT US</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={3}><img src={'/images/kh/2023/us2.jpeg'} alt={'team'}
                                               style={{width: '100%', marginTop: 5}}/></Grid>
                        <Grid item xs={9}>
                            <Typography variant={'body1'} gutterBottom>
                                I am a software engineer, my wife does UX design and business development. Our son is Nile
                                turns 3 this month and does everything almost perfectly.
                            </Typography>
                            <Typography variant={'body1'} gutterBottom>
                                As much as we love Kauai, we also also entrepreneurs and need the larger market of Oahu to test drive a product
                                idea - <a href={"https://pickupmvp.com"} target={"_blank"}>PickupMVP.com</a>. So we're looking to find a family, couple or individual who might want to trade places on and
                                off.
                            </Typography>
                        </Grid>
                    </Grid>

                </section>
            </div>
        );
    }
}


export default withStyles(styles)(HaleTrade);

