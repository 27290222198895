import React from 'react';
import TextField from '@material-ui/core/TextField';
import {Link, useHistory} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Fade from '@material-ui/core/Fade';
import BgImageFader from "./BgImageFader";
import GardenHwy from "./GardenHwy";
import CoopPlans from "./CoopPlans";
import HaleTrade from "./HaleTrade";

const SPACES = {riverhouse: true, reddirt: true, kauai: true, elephante: true}

const WorkspaceLogin = (p) => {

    const history = useHistory();
    const [pass, setPass] = React.useState(p.pass || '');
    const [canAccess, setAccess] = React.useState(false);

    history.listen((location, action) => {
        // console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
        // console.log(`The last navigation action was ${action}`)
        if (location.pathname === '/workspaces') {
            setPass('')
            setAccess(false);
        }
    })

    React.useEffect(() => {
        let pass2 = pass.toLowerCase();
        if (canAccess === false && typeof SPACES[pass2] !== 'undefined') {
            setAccess(true);
            window.logEvent('workspaces', {'id':pass2});
            history.push('/workspaces/' + pass2, {pass:pass2});
        }
    }, [pass, canAccess, history]);

    function handleCreds(e) {
        setPass(e.currentTarget.value);
    }

    if (canAccess !== true) {

        let field = <Box m={'auto'} p={3} style={{textAlign: 'center'}}>
            <TextField
                variant={'filled'} fullWidth={true} onChange={handleCreds}
                value={pass} label={"Enter name of the Garden / Workspace"} />
        </Box>

        if (p.layout === 'field') return field;
        return (<section id='login' >
                <header style={{margin: '40px auto', textAlign: 'center'}}>
                    <Link to={'/'}><img src='/logo.png' style={{textAlign: 'center'}} alt="logo" width='200' /></Link>
                </header>
                {field}
                <BgImageFader />
            </section>)
    }

    if (pass.toLowerCase() === 'riverhouse') return <Fade in={true} timeout={1500}><GardenHwy /></Fade>
    if (pass.toLowerCase() === 'kauai') return <Fade in={true} timeout={1500}><HaleTrade /></Fade>
    return <Fade in={true} timeout={1500}><CoopPlans /></Fade>
}

export default WorkspaceLogin;
